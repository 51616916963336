import { NgModule } from "@angular/core";
import { LoginComponent } from "./pages";
import { SharedModule } from '@shared/shared.module';
import { AuthRoutingFeatureModule } from "./auth-routing.feature.module";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    imports: [
        SharedModule,
        AuthRoutingFeatureModule,
        ReactiveFormsModule,
    ],
    providers: [],
    declarations: [
        LoginComponent
    ],
})
export class AuthFeatureModule { }