import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Credentials, User } from '@core/models';
import { AuthenticationService, UserSessionPersistenceService } from '@core/services';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public form!: FormGroup;
  private returnUrl: string | undefined;
  private ipAddress: string | undefined;

  constructor(private route: ActivatedRoute, private fb: FormBuilder,
    private authenticationService: AuthenticationService, private router: Router,
    private userSessionPersistenceService: UserSessionPersistenceService,
    private deviceService: DeviceDetectorService) {
    this.initializeForm();
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  login() {
    if (this.form!.invalid) {
      return;
    }

    var credentials = new Credentials();
    credentials = {
      ...credentials,
      ...this.form!.value,
    };
    credentials.device = this.deviceService.getDeviceInfo().userAgent;

    this.authenticationService
      .login(credentials)
      .subscribe(
        {
          next: (user: User) => {
            this.userSessionPersistenceService.setUserSession(user.userSession!);
            this.router.navigate([this.returnUrl]);
          }
        }
      );
  }
}
