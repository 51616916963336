<mat-card-title class="text-center mb-4">
    <h1>Login</h1>
</mat-card-title>
<mat-card-content>
    <form class="form-horizontal" [formGroup]="form">
        <mat-form-field appearance="outline">
            <mat-label>Username or Email
            </mat-label>
            <input matInput placeholder="Username or Email" formControlName="username" required>
            <mat-error *ngIf="form.controls['username'].invalid">
                Username or Email is required!</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput placeholder="Password" type="password" formControlName="password" required>
            <mat-error *ngIf="form.controls['password'].invalid">
                Password is required!</mat-error>
        </mat-form-field>

        <button class="w-100 mt-2" mat-raised-button color="primary" (click)="login()"
            [disabled]="form.invalid">Login</button>
    </form>
</mat-card-content>